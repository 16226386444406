import debounce from "lodash.debounce";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import RealEstateAndLoansInput from "@/components/input/wizards/realEstateAndLoans/realEstateAndLoansInput";
import RealEstateAndLoansResult from "@/components/input/wizards/realEstateAndLoans/realEstateAndLoansResult";
import TaxReturnCodes from "@/components/input/wizards/realEstateAndLoans/taxReturnCodes";
import Simulation from "@/components/simulation";
import SimulationInput from "@/components/input/simulationInput";
import Loan from "~/components/input/wizards/realEstateAndLoans/loans/loan";
import Refinancing from "~/components/input/wizards/realEstateAndLoans/loans/refinancing";
import Property from "~/components/input/wizards/realEstateAndLoans/realEstate/property";

export default class RealEstateAndLoans {
  constructor(input: RealEstateAndLoansInput, result: RealEstateAndLoansResult | null, id: string | null = null) {
    this.input = input;
    this.result = result;
    this.id = id === null ? uuidv4() : id;
  }

  id: string;

  input: RealEstateAndLoansInput;

  result: RealEstateAndLoansResult | null;

  apiAddress = process.env.apiUrl!.concat("personal-income-tax-wizards/v1/");

  optimizePartnerDistribution: boolean = true;

  includeNoneValues: boolean = true;

  static caseIdEncrypted: string | undefined = undefined;

  static token: string | undefined = undefined;

  apiRequestsPending: number = 0;

  apiSyntaxException: string | null = null;

  apiOtherException: string | null = null;

  apiMissingFields: string[] | null = null;

  updateResultDebounced = debounce(
    (doWhenSuccess: any = undefined, doWhenEnded: any = undefined) => this.updateResult(doWhenSuccess, doWhenEnded),
    20
  );

  get hasValue(): boolean {
    return this.input.realEstate.length > 0 || this.input.loans.length > 0;
  }

  get apiRequestPending(): boolean {
    return this.apiRequestsPending !== 0;
  }

  get apiExceptions(): boolean {
    return !!(this.apiOtherException || this.apiSyntaxException || this.apiMissingFields);
  }

  get isUpToDate(): boolean {
    return this.result ? this.input.requestHash === this.result.requestHash : false;
  }

  get primedForCalculation(): boolean {
    return this.input.enabled && this.input.isValid() && !this.isUpToDate;
  }

  get isValid(): boolean {
    return this.input.isValid() && !this.apiExceptions;
  }

  get isValidAndUpToDate(): boolean {
    return this.input.isValid() && !this.apiExceptions && this.isUpToDate;
  }

  disable() {
    this.input.enabled = false;
    this.apiMissingFields = null;
    this.apiSyntaxException = null;
    this.apiOtherException = null;
    this.result = null;
  }

  savePropertyAndLoans(property: Property, savedLoans: Loan[] | undefined, removedLoans: string[] | undefined) {
    const indexOfProperty = this.input.realEstate.map((item) => item.propertyId).indexOf(property.propertyId);
    if (indexOfProperty > -1) {
      this.input.realEstate[indexOfProperty] = property;
    } else {
      this.input.realEstate.push(property);
    }
    if (savedLoans) {
      savedLoans.forEach((loan) => this.saveLoan(loan));
    }
    if (removedLoans) {
      removedLoans.forEach((loanId) => {
        const loanIndex = this.input.loans.map((item) => item.loanId).indexOf(loanId);
        if (loanIndex > -1) {
          this.input.loans.splice(loanIndex, 1);
        }
      });
    }
  }

  removeProperty(property: Property) {
    const indexOfProperty = this.input.realEstate.map((item) => item.propertyId).indexOf(property.propertyId);
    if (indexOfProperty > -1) {
      const loans = this.input.loansForPropertyId(property.propertyId!);
      const loansToRemove = loans.filter((loan) => loan.properties.length === 1);
      loansToRemove.forEach((loan) => {
        const loanIndex = this.input.loans.map((item) => item.loanId).indexOf(loan.loanId);
        this.input.loans.splice(loanIndex, 1);
      });
      this.input.realEstate.splice(indexOfProperty, 1);
    }
    if (this.input.realEstate.length === 0) {
      this.disable();
    }
  }

  saveLoan(loan: Loan) {
    const indexOfLoan = this.input.loans.map((item) => item.loanId).indexOf(loan.loanId);
    if (indexOfLoan > -1) {
      this.input.loans[indexOfLoan] = loan;
    } else {
      this.input.loans.push(loan);
    }
  }

  removeLoan(loan: Loan) {
    const indexOfLoan = this.input.loans.map((item) => item.loanId).indexOf(loan.loanId);
    if (indexOfLoan > -1) {
      this.input.loans.splice(indexOfLoan, 1);
    }
  }

  updateResult(doWhenSuccess: any = undefined, doWhenEnded: any = undefined): void {
    if (this.input.isValid() && window?.navigator?.onLine) {
      this.apiRequestsPending += 1;

      this.getResultPromise(this.input)
        .then((result: RealEstateAndLoansResult) => {
          if (this.apiRequestsPending > 0) {
            this.apiRequestsPending -= 1;
          }
          // if no new requests
          if (result.requestHash === this.input.requestHash) {
            this.result = result;
            this.apiRequestsPending = 0;
            this.apiOtherException = null;
            this.apiSyntaxException = null;
            this.apiMissingFields = null;

            if (doWhenSuccess) {
              doWhenSuccess();
            }
          }
        })
        .catch((error: any) => {
          if (this.apiRequestsPending > 0) {
            this.apiRequestsPending -= 1;
          }
          if (!this.isUpToDate) {
            if (error.response && error.response.status === 400) {
              if ("missing_fields" in error.response.data) {
                this.apiMissingFields = error.response.data.missing_fields;
              } else if ("message" in error.response.data) {
                this.apiSyntaxException = error.response.data.message;
              } else {
                this.apiSyntaxException = error.toString();
              }
            } else if (error.response && error.response.status === 403) {
              this.apiOtherException = "Access forbidden";
            } else {
              this.apiOtherException =
                error.response && error.response.data && "message" in error.response.data
                  ? error.response.data.message
                  : error.toString();
            }
          }
        })
        .finally(() => {
          if (doWhenEnded) {
            doWhenEnded();
          }
        });
    } else {
      if (doWhenEnded) {
        doWhenEnded();
      }
    }
  }

  async getResultPromise(input: RealEstateAndLoansInput): Promise<RealEstateAndLoansResult> {
    const data = input.toObject();
    data.options = {
      optimize_partner_distribution: this.optimizePartnerDistribution,
      include_none_values: this.includeNoneValues,
    };
    if (!input.currentReturn.isDoubleReturn) {
      for (const property of data.real_estate) {
        delete property.income_share_declarant;
        delete property.ownership_share_partner;
      }
      for (const loan of data.loans) {
        delete loan.life_insurance_partner;
        delete loan.details_partner;
        if (loan.details_declarant) {
          delete loan.details_declarant.forced_property_income_share;
        }
      }
    }

    const headers: { [key: string]: string } = {
      "x-api-key": Simulation.apiKey,
    };
    if (Simulation.caseIdEncrypted) {
      headers["X-Case-Id-Encrypted"] = Simulation.caseIdEncrypted;
    }
    if (Simulation.token) {
      headers["Authorization"] = `Bearer ${Simulation.token}`;
    }

    const resultObject = await axios.post(`${this.apiAddress}${input._taxYear}/real-estate-and-loans`, data, {
      timeout: 10000,
      headers: headers,
    });

    return RealEstateAndLoansResult.fromObject(resultObject.data, input.requestHash);
  }

  get isReady(): boolean {
    return !this?.apiExceptions && !this?.apiRequestPending;
  }

  // returns map<LoanId, Field>
  missingLoanFieldsForProperty(propertyId: string): Map<string, string[]> | null {
    if (this.apiMissingFields && this.apiMissingFields.length > 0) {
      const loanIdsForProperty = this.input.loansForPropertyId(propertyId).map((loan) => loan.loanId);
      const regex = /^loans\[(.*)]\.([a-zA-Z0-9._]+)$/;
      const relevantFields = this.apiMissingFields
        .map((message) => {
          const match = message.match(regex)!;
          return [match[1], match[2]];
        })
        .filter(([loanId]) => loanIdsForProperty.includes(loanId));
      if (relevantFields.length > 0) {
        const result = new Map();
        relevantFields.forEach(([loanId, field]) => {
          if (!result.has(loanId)) {
            result.set(loanId, [field]);
          } else {
            result.get(loanId).push(field);
          }
        });
        return result;
      } else {
        return null;
      }
    }
    return null;
  }

  missingLoanFieldsForLoan(loan: Loan | Refinancing): string[] | null {
    if (this.apiMissingFields && this.apiMissingFields.length > 0) {
      const regex = /^loans\[(.*)]\.([a-zA-Z0-9._]+)$/;
      const relevantFields = this.apiMissingFields
        .map((message) => {
          const match = message.match(regex)!;
          return [match[1], match[2]];
        })
        .filter(([loanId]) => loanId === loan.loanId)
        .map(([loanId, message]) => message);
      if (relevantFields.length > 0) {
        return relevantFields;
      } else {
        return null;
      }
    }
    return null;
  }

  static fromInput(input: RealEstateAndLoansInput): RealEstateAndLoans {
    return new RealEstateAndLoans(input, null);
  }

  clone(): RealEstateAndLoans {
    const clone = new RealEstateAndLoans(this.input.clone(), this.result ? this.result.clone() : null, this.id);
    clone.optimizePartnerDistribution = this.optimizePartnerDistribution;
    clone.includeNoneValues = this.includeNoneValues;
    clone.apiRequestsPending = this.apiRequestsPending;
    clone.apiSyntaxException = this.apiSyntaxException;
    clone.apiOtherException = this.apiOtherException;
    clone.apiMissingFields = this.apiMissingFields ? [...this.apiMissingFields] : this.apiMissingFields;
    return clone;
  }

  applyOnSimulationInput(simulationInput: SimulationInput): void {
    const inputObject = this.input.toObject();
    simulationInput.realEstate = inputObject.real_estate;
    simulationInput.loans = inputObject.loans;
    if (this.input.enabled) {
      simulationInput.enableWizard("real_estate_and_loans");
      if (simulationInput.codeItems) {
        // removes existing codes
        const newCodeItems = Array.from(simulationInput.codeItems.values());
        let updated = false;
        newCodeItems.forEach((item) => {
          if (item.tags.includes("from_wizard:real_estate_and_loans")) {
            simulationInput.removeCode(item.info.code, false);
            simulationInput.removeTagForCode(item.info.code, "from_wizard:real_estate_and_loans");
            updated = true;
          }
        });
        if (updated) {
          simulationInput.update();
        }
      }
      if (this.result) {
        simulationInput.realEstateAndLoansResult = this.result?.toObject();
      }
    } else {
      simulationInput.disableWizard("real_estate_and_loans");
      simulationInput.realEstateAndLoansResult = null;
      simulationInput.update();
    }

    if (this.result && this.result.hasResultCodes && simulationInput.codeItems) {
      this.result.realEstateCodes.foreignCodes.forEach((currentForeignCode) => {
        simulationInput.foreignCodes = simulationInput.foreignCodes.filter((fc) => fc.code !== currentForeignCode.code);
      });
      this.result.realEstateCodes.foreignCodes.forEach((currentForeignCode) => {
        simulationInput.foreignCodes.push(currentForeignCode);
      });

      Object.entries(this.result.loanCodes).forEach(([codeId, value]) => {
        if (value != null) {
          simulationInput.setCode(codeId, value, true);
          simulationInput.addTagForCode(codeId, "from_wizard:real_estate_and_loans");
        } else {
          simulationInput.removeCode(codeId, true);
          simulationInput.addTagForCode(codeId, "from_wizard:real_estate_and_loans");
        }
      });
      Object.entries(this.result.realEstateCodes.codes).forEach(([codeId, value]) => {
        if (value != null) {
          simulationInput.setCode(codeId, value, true);
          simulationInput.addTagForCode(codeId, "from_wizard:real_estate_and_loans");
        } else {
          simulationInput.removeCode(codeId, true);
          simulationInput.addTagForCode(codeId, "from_wizard:real_estate_and_loans");
        }
      });
    }
  }

  static fromSimulationInput(simulationInput: SimulationInput, id: string | null = null): RealEstateAndLoans {
    // current return should only contain the non_removed codes
    const codes = Object.fromEntries(
      Object.entries(simulationInput.codes).filter(([codeId, value]) =>
        simulationInput.codeTags
          ? !simulationInput.codeTags[codeId]?.includes("from_wizard:real_estate_and_loans")
          : true
      )
    );
    const foreignCodes = simulationInput.foreignCodes.filter((fc) => codes[fc.code] != null);
    return new RealEstateAndLoans(
      RealEstateAndLoansInput.fromObject(
        {
          real_estate: simulationInput.realEstate ? simulationInput.realEstate : [],
          loans: simulationInput.loans ? simulationInput.loans : [],
          enabled: simulationInput.enabledWizards.includes("real_estate_and_loans"),
          current_return: new TaxReturnCodes(codes, foreignCodes),
          long_term_savings_declarant:
            simulationInput.enabledWizards.includes("savings") &&
            simulationInput.savings &&
            simulationInput.savings.long_term_savings_declarant
              ? simulationInput.savings.long_term_savings_declarant
              : undefined,
          long_term_savings_partner:
            simulationInput.enabledWizards.includes("savings") &&
            simulationInput.savings &&
            simulationInput.savings.long_term_savings_partner
              ? simulationInput.savings.long_term_savings_partner
              : undefined,
        },
        simulationInput.taxYear
      ),
      simulationInput.realEstateAndLoansResult
        ? RealEstateAndLoansResult.fromObject(simulationInput.realEstateAndLoansResult, "")
        : null,
      id
    );
  }

  static fromSimulation(simulation: Simulation): RealEstateAndLoans {
    return RealEstateAndLoans.fromSimulationInput(simulation.input, simulation.id);
  }

  static fromObject(obj: any, taxYear: number): RealEstateAndLoans {
    if (!(obj.id && obj.input)) {
      throw Error(`not a valid real estate/loan set: ${JSON.stringify(obj)}`);
    }
    const input = RealEstateAndLoansInput.fromObject(obj.input, taxYear);
    return new RealEstateAndLoans(
      input,
      obj.result ? RealEstateAndLoansResult.fromObject(obj.result, input.requestHash) : null,
      obj.id
    );
  }

  toObject(includeOnlyUpToDatResult: boolean = true): any {
    return {
      id: this.id,
      input: this.input.toObject(),
      result: this.result && (!includeOnlyUpToDatResult || this.isUpToDate) ? this.result.toObject() : null,
    };
  }

  toPostMessageState() {
    return {
      realEstateAndLoans: this.toObject(),
      validationErrors: this.input.validationErrors,
      apiSyntaxError: this.apiSyntaxException,
      apiOtherError: this.apiOtherException,
      enabled: this.input.enabled,
      isUpToDate: this.isUpToDate,
    };
  }
}
