import SimulationInput from "@/components/input/simulationInput";
import axios from "axios";
import Simulation from "@/components/simulation";

export default class FileParser {
  constructor(i18n: any, validYears: number[], apiKey: string) {
    this.i18n = i18n;
    this.validYears = validYears;
    this.apiKey = apiKey;
  }

  i18n: any;

  validYears: number[];

  apiKey: string;

  simulation: Simulation | null = null;

  documentHandlingEntry = process.env.apiUrl!.concat("document-handling/v1/");

  uploading: boolean = false;

  parseInputError: string | null = null;

  parseInputWarning: string | null = null;

  parseFileLocally(file: File) {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.addEventListener("load", () => {
      try {
        const simulationObject = JSON.parse(reader.result as string);
        if (simulationObject.input) {
          this.simulation = Simulation.fromObject(simulationObject);
        } else {
          this.simulation = Simulation.fromInput(
            new SimulationInput(
              simulationObject.taxYear,
              simulationObject.city,
              simulationObject.codes,
              simulationObject.foreignCodes,
              simulationObject.nationalIdNumber,
              simulationObject.userComments,
              simulationObject.codeTags,
              simulationObject.realEstate,
              simulationObject.loans,
              simulationObject.savings,
              simulationObject.household,
              simulationObject.enabledWizards,
              simulationObject.realEstateAndLoansResult
            )
          );
        }
        this.parseInputError = null;
        this.parseInputWarning = null;
      } catch (e) {
        this.simulation = null;
        this.parseInputError = `Not able to parse file: ${e}`;
      }
    });
  }

  parseFileRemotely(file: File) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("include_personal_info", "true");
    formData.append("parse_sum_fields", "true");
    this.uploading = true;

    const headers: { [key: string]: string } = {
      "x-api-key": this.apiKey,
    };
    if (Simulation.caseIdEncrypted) {
      headers["X-Case-Id-Encrypted"] = Simulation.caseIdEncrypted;
    }
    if (Simulation.token) {
      headers["Authorization"] = `Bearer ${Simulation.token}`;
    }

    axios
      .post(this.documentHandlingEntry.concat("parse"), formData, {
        timeout: 10000,
        headers: headers,
      })
      .then((response) => {
        this.parseInputError = "";

        const taxYear = response.data.declarations[0].tax_year;
        if (!this.validYears.includes(taxYear)) {
          this.parseInputError = this.i18n.t("tax_year_not_valid", [taxYear]).toString();
        } else {
          const simulationInput = new SimulationInput(
            taxYear,
            response.data.declarations[0].city,
            response.data.declarations[0].codes,
            response.data.declarations[0].foreign_codes,
            response.data.declarations[0].declarant_info?.national_id_number,
            null,
            null,
            null,
            null,
            null,
            null,
            [],
            null
          );
          if (
            simulationInput.codes &&
            !(1086 in simulationInput.codes) &&
            response.data.declarations[0].declarant_info?.date_of_birth
          ) {
            const dt: string = response.data.declarations[0].declarant_info.date_of_birth;
            simulationInput.codes = {
              ...simulationInput.codes,
              1086: `${dt.substring(8, 10)}/${dt.substring(5, 7)}/${dt.substring(0, 4)}`,
            };
          }
          if (
            simulationInput.codes &&
            !(2086 in simulationInput.codes) &&
            response.data.declarations[0].declarant_info_partner?.date_of_birth
          ) {
            const dt: string = response.data.declarations[0].declarant_info_partner.date_of_birth;
            simulationInput.codes = {
              ...simulationInput.codes,
              2086: `${dt.substring(8, 10)}/${dt.substring(5, 7)}/${dt.substring(0, 4)}`,
            };
            if (simulationInput.codes["noEntA"] && typeof simulationInput.codes["noEntA"] !== "string") {
              simulationInput.codes["noEntA"] = `${simulationInput.codes["noEntA"]}`.padStart(10, "0");
            }
            if (simulationInput.codes["noEntB"] && typeof simulationInput.codes["noEntB"] !== "string") {
              simulationInput.codes["noEntB"] = `${simulationInput.codes["noEntB"]}`.padStart(10, "0");
            }
          }
          if (simulationInput.codes["noEntA"] && typeof simulationInput.codes["noEntA"] !== "string") {
            simulationInput.codes["noEntA"] = `${simulationInput.codes["noEntA"]}`.padStart(10, "0");
          }
          if (simulationInput.codes["noEntB"] && typeof simulationInput.codes["noEntB"] !== "string") {
            simulationInput.codes["noEntB"] = `${simulationInput.codes["noEntB"]}`.padStart(10, "0");
          }
          this.simulation = Simulation.fromInput(simulationInput);

          if (response.data.warning) {
            this.parseInputWarning = `${this.i18n.t("warning").toUpperCase}: ${response.data.warning}`;
          } else {
            this.parseInputWarning = null;
          }
        }
      })
      .catch((error) => {
        console.log(error);
        this.parseInputError =
          error.response && error.response.data && "message" in error.response.data
            ? error.response.data.message
            : error.toString();
      })
      .finally(() => {
        this.uploading = false;
      });
  }

  parseFileKluwer(file: File) {
    const formData = new FormData();
    formData.append("file", file);
    this.uploading = true;

    const headers: { [key: string]: string } = {
      "x-api-key": this.apiKey,
    };
    if (Simulation.caseIdEncrypted) {
      headers["X-Case-Id-Encrypted"] = Simulation.caseIdEncrypted;
    }
    if (Simulation.token) {
      headers["Authorization"] = `Bearer ${Simulation.token}`;
    }

    axios
      .post(this.documentHandlingEntry.concat("parse-kluwer-pit"), formData, {
        timeout: 10000,
        headers: headers,
      })
      .then((response) => {
        this.parseInputError = "";

        const taxYear = response.data.tax_year;
        if (!this.validYears.includes(taxYear)) {
          this.parseInputError = this.i18n.t("tax_year_not_valid", [taxYear]).toString();
        } else {
          const simulationInput = new SimulationInput(
            taxYear,
            response.data.city,
            response.data.codes,
            response.data.foreign_codes,
            null,
            null,
            null,
            response.data.real_estate,
            response.data.loans,
            null,
            null,
            [],
            null
          );
          this.simulation = Simulation.fromInput(simulationInput);

          if (response.data.tax_machine_result && response.data.tax_machine_result.diff_exceeds_threshold) {
            this.parseInputWarning = `${this.i18n.t("warning").toUpperCase}: diff exceeds threshold`;
          } else {
            this.parseInputWarning = null;
          }
        }
      })
      .catch((error) => {
        console.log(error);
        this.parseInputError =
          error.response && error.response.data && "message" in error.response.data
            ? error.response.data.message
            : error.toString();
      })
      .finally(() => {
        this.uploading = false;
      });
  }
}
