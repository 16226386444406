
import { Component, Vue } from "nuxt-property-decorator";
import PersonalIncomeTaxReturn from "~/components/PersonalIncomeTaxReturn.vue";
import Simulation from "~/components/simulation";

@Component({
  components: { PersonalIncomeTaxReturn },
})
export default class Index extends Vue {
  hasApiKey: boolean = false;
  loaded: boolean = false;

  head() {
    return {
      title: this.$i18n.t("homepage.page_title"),
      meta: [
        {
          hid: "description",
          name: "description",
          content: this.$i18n.t("homepage.page_description"),
        },
      ],
    };
  }

  mounted() {
    if (Simulation.apiKey == "") {
      Simulation.apiKey = localStorage.getItem("pitApiKey") ?? "";
    }
    if (Simulation.apiKey) {
      this.hasApiKey = true;
    }
    this.loaded = true;
  }

  get queryInput(): string | undefined {
    return this.$route.query.input
      ? Buffer.from(this.$route.query.input! as string, "base64").toString("utf-8")
      : undefined;
  }
}
